const DropDownLocation = (el) => {
	const dropDownButton = el.querySelector('.dropDownLocation__toggleButton');
	const dropDownTop = el.querySelector('.dropDownLocation__topSection');
	const dropDownBottom = el.querySelector('.dropDownLocation__bottomSection');
	const dropDownDescription = el.querySelector('.dropDownLocation__description');
	const slideshowLocation = el.querySelector('.slideshowLocation');

	const isMobile = () => window.matchMedia('(max-width: 960px)');
	let lastIsMobile = '';

	const setHeight = () => {
		const { height } = dropDownBottom.getBoundingClientRect();
		dropDownBottom.style.setProperty('--height', `${height}px`);
		dropDownBottom.classList.add('setHeight');

		if (el.querySelector('.active:not(.slideshow__slide)')) {
			toggleActive();
		} else {
			dropDownBottom.classList.add('inactive');
		}
	};

	const toggleActive = () => {
		lastIsMobile = isMobile().matches;

		dropDownBottom.classList.toggle('inactive');
		dropDownDescription.classList.toggle('active');
		dropDownButton.classList.toggle('active');
		slideshowLocation.classList.toggle('active')
	}

	const handleResize = () => {
		if (isMobile().matches && lastIsMobile) return;
		dropDownBottom.classList.remove('setHeight');
		setHeight();
	}

	const init = () => {
		setHeight();
		dropDownButton.addEventListener('click', toggleActive);
		window.addEventListener('resize', handleResize);
	};

	init();
};

export default DropDownLocation;