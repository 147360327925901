const Slideshow = (el) => {
	const baseName     = 'slideshow',
		  slidesImages = [...el.querySelectorAll(`.slideshowLocation__image .${baseName}__slide`)],
		  slidesNames  = [...el.querySelectorAll(`.slideshowLocation__name .${baseName}__slide`)],
		  buttons      = [...el.querySelectorAll(`.${baseName}__button`)],
		  slidesImagesLength = slidesImages.length,
		  autoplay     = el.dataset.autoplay || false;

	const clearSlideTransitionning = (e) => {
		const {target}  = e,
			  isActive = target.classList.contains('active'),
			  classToRemove = [...target.classList].filter(className => className.startsWith(isActive ? 'isTransitionning' : 'isSetting'))?.[0];

		target.classList.remove('isTransitionning');
		target.classList.remove(classToRemove);
		target.classList[isActive ? 'remove' : 'add']('active');		
	};

	const navigate = (e) => {
		if (document.querySelector('.isTransitionning')) return;

		const {target}  = e,
			  direction = Number(target.dataset.direction),
			  index     = slidesImages.findIndex(element => element.classList.contains('active'));
		
		let newActiveSlideIndex = direction === 0 ? index - 1 : index + 1;

		if (index === 0 && direction === 0) {
			newActiveSlideIndex = slidesImagesLength - 1
		} else if (index === slidesImagesLength - 1 && direction > 0) {
			newActiveSlideIndex = 0
		}
		
		slidesImages[newActiveSlideIndex].classList.add(`isSetting-${direction === 0 ? 'previous' : 'next'}`);
		slidesImages[index].classList.add(`isTransitionning-${direction === 0 ? 'previous' : 'next'}`);

		slidesNames[newActiveSlideIndex].classList.add(`isSetting-${direction === 0 ? 'previous' : 'next'}`);
		slidesNames[index].classList.add(`isTransitionning-${direction === 0 ? 'previous' : 'next'}`);

		setTimeout(() => {
			slidesImages[newActiveSlideIndex].classList.add('isTransitionning');
			slidesNames[newActiveSlideIndex].classList.add('isTransitionning');
		}, 10);
	};

	const init = () => {
		slidesImages[0].classList.add('active');
		slidesNames[0].classList.add('active');

		if (autoplay) {
			setInterval(navigate, 5000, {
				target: {
					dataset: {
						direction: 1
					}
				}
			})
		} else {
			for (const button of buttons) {
				button.addEventListener('click', navigate);
			}
		}

		for (const slide of slidesImages) {
			slide.addEventListener('transitionend', clearSlideTransitionning);
		}

		for (const slide of slidesNames) {
			slide.addEventListener('transitionend', clearSlideTransitionning);
		}
	};

	init();
};

export default Slideshow;