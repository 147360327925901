import './styles/main.scss';

import SlideshowLocation from './scripts/slideshowLocation';
import Slideshow from './scripts/slideshow';
import DropDownLocation from './scripts/dropDownLocation';

const slideshowModules = [...document.querySelectorAll('[data-module="slideshow"]')];
const slideshowLocationModules = [...document.querySelectorAll('[data-module="slideshowLocation"]')];
const dropDownLocationModules = [...document.querySelectorAll('[data-module="dropDownLocation"]')];

for (const s of slideshowModules) {
	Slideshow(s);
}

for (const s of slideshowLocationModules) {
	SlideshowLocation(s);
}

for (const d of dropDownLocationModules) {
	DropDownLocation(d);
}