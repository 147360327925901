const Slideshow = (el) => {
	const baseName     = 'slideshow',
		  slides       = [...el.querySelectorAll(`.${baseName}__slide`)],
		  buttons      = [...el.querySelectorAll(`.${baseName}__button`)],
		  calcs        = [...el.querySelectorAll(`.${baseName}__calc`)],
		  slidesLength = slides.length,
		  autoplay     = el.dataset.autoplay || false;

	const clearCalcTransitionning = (e) => {
		e.target.classList.remove('isTransitionning-calc');
	};

	const clearSlideTransitionning = (e) => {
		const {target}  = e,
			  isActive = target.classList.contains('active'),
			  classToRemove = [...target.classList].filter(className => className.startsWith(isActive ? 'isTransitionning' : 'isSetting'))?.[0];

		target.classList.remove('isTransitionning');
		target.classList.remove(classToRemove);
		target.classList[isActive ? 'remove' : 'add']('active');		
	};

	const navigate = (e) => {
		if (document.querySelector('.isTransitionning-calc')) return;

		const {target}  = e,
			  direction = Number(target.dataset.direction),
			  calc      = calcs[direction],
			  index     = slides.findIndex(element => element.classList.contains('active'));
		
		let newActiveSlideIndex = direction === 0 ? index - 1 : index + 1;

		if (index === 0 && direction === 0) {
			newActiveSlideIndex = slidesLength - 1
		} else if (index === slidesLength - 1 && direction > 0) {
			newActiveSlideIndex = 0
		}

		calc.classList.add('isTransitionning-calc');
		
		slides[newActiveSlideIndex].classList.add(`isSetting-${direction === 0 ? 'previous' : 'next'}`);
		slides[index].classList.add(`isTransitionning-${direction === 0 ? 'previous' : 'next'}`);

		setTimeout(() => {
			slides[newActiveSlideIndex].classList.add('isTransitionning');
		}, 10);
	};

	const init = () => {
		slides[0].classList.add('active');

		if (autoplay) {
			setInterval(navigate, 5000, {
				target: {
					dataset: {
						direction: 1
					}
				}
			})
		} else {
			for (const button of buttons) {
				button.addEventListener('click', navigate);
			}
		}

		for (const calc of calcs) {
			calc.addEventListener('transitionend', clearCalcTransitionning);
		}

		for (const slide of slides) {
			slide.addEventListener('transitionend', clearSlideTransitionning);
		}
	};

	init();
};

export default Slideshow;